// import withReactContent from 'sweetalert2-react-content'
// import { Swal } from 'sweetalert2/dist/sweetalert2'
// // const DialogSwal = withReactContent(Swal)

// export const showAlert = (message, type) => {
//   DialogSwal.fire({
//     icon: type,
//     title: message,
//     showConfirmButton: false,
//     timer: 1500
//   })
// }
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export const showAlert = (title, text, type, callback = () => {}) =>
  MySwal.fire({
    title,
    text,
    icon: type,
    allowOutsideClick: false,
    confirmButtonColor: '#d1df4e',
    confirmButtonText: 'Accept',
    cancelButtonColor: '#005570',
    showCancelButton: type === 'question',
    customClass: {
      container: 'alert-backdrop',
      popup: 'alert-popup',
      title: 'alert-title',
      icon: 'alert-icon',
      input: 'alert-input',
      htmlContainer: 'alert-text',
      cancelButton: 'alert-button alert-button--cancel',
      confirmButton: 'alert-button alert-button--confirm'
    }
  }).then(({ isConfirmed }) => {
    if (isConfirmed) callback()
  })
