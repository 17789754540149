// #region Dependencies
import { useContext, useLayoutEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { UserContext } from 'context/UserContext'
import { Button } from 'components/Button'
import { IconLogout } from 'components/Icons'
import dashboardHeaderBg from 'assets/images/panel-header-background.png'
import cweLogo from 'assets/images/icons/logo.svg'
// #endregion Dependencies

export const NavBar = () => {
  const [showShadow, setShowShadow] = useState(false)
  const { teacherData, logoutTeacher } = useContext(UserContext)

  /**
   * Function to handle the logout of the teacher
   */
  const handleLogoutTeacher = () => {
    logoutTeacher()
  }

  /**
   * Hook useLayoutEffect to handle the scroll event
   * and show the shadow in the header
   */
  useLayoutEffect(() => {
    const mainElement = document.getElementById('main')
    const handleScroll = () => {
      if (mainElement.scrollTop <= 1) setShowShadow(false)
      if (mainElement.scrollTop > 0) setShowShadow(true)
    }
    mainElement.addEventListener('scroll', handleScroll)
    return () => mainElement.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <header
      className={`navbar ${showShadow && 'navbar--shadow'}`}
      style={{
        backgroundImage: `url(${dashboardHeaderBg})`
      }}
    >
      <div className="navbar-content">
        <div className="navbar-links">
          <NavLink
            className={({ isActive }) =>
              `navbar-link__item ${isActive && 'navbar-link__item--active'}`
            }
            to="/students"
          >
            <span>Students</span>
          </NavLink>
          {teacherData?.superadmin && (
            <>
              <NavLink
                className={({ isActive }) =>
                  `navbar-link__item ${isActive && 'navbar-link__item--active'}`
                }
                to="/admin/users"
              >
                <span>Admin</span>
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  `navbar-link__item ${isActive && 'navbar-link__item--active'}`
                }
                to="/admin/texts"
              >
                <span>Text editing</span>
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  `navbar-link__item ${isActive && 'navbar-link__item--active'}`
                }
                to="/admin/questions"
              >
                <span>Question editing</span>
              </NavLink>
            </>
          )}
        </div>
        <div className="navbar-info">
          <img
            className="navbar-link__item--logo"
            src={cweLogo}
            alt="Career World Explorer Logo"
          />
          <Button
            className="navbar-link__item navbar-link__item--logout"
            onClick={handleLogoutTeacher}
          >
            <IconLogout />
          </Button>
        </div>
      </div>
    </header>
  )
}
