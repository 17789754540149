// #region Dependencies
import { Navigate, Outlet } from 'react-router-dom'
// #endregion Dependencies

export const AdminRoute = ({ isSuperAdmin }) => {
  if (!isSuperAdmin) {
    return <Navigate to="/students" />
  }

  return <Outlet />
}
