// #region Dependencies
import { createContext, useState } from 'react'
import { db } from 'services/firebase'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc
} from 'firebase/firestore'
import { showAlert } from 'utils/alerts'
import {
  getLastUpdateRequest,
  setLastUpdateRequest
} from 'utils/handleLastUpdate'
// #endregion Dependencies

const WorldContext = createContext()
const WorldProvider = ({ children }) => {
  const [loadingWorlds, setLoadingWorlds] = useState(false)
  const [initialLoad, setInitialLoad] = useState(false)

  const [worlds, setWorlds] = useState({
    medical: null,
    tech: null,
    service: null,
    nature: null,
    build: null
  })

  /**
   * Function to get all admins data
   */
  const getWorldRequest = (world) => {
    setLoadingWorlds(true)
    const worldRef = doc(db, 'worlds', world)

    getDoc(worldRef)
      .then((doc) => {
        const worldData = doc.data()
        setWorlds((worlds) => ({ ...worlds, [world]: worldData }))
      })
      .catch((error) => {
        console.error('error :>> ', error)
        showAlert(
          'Error',
          'Error getting world data, reload the page and try again',
          'error'
        )
      })
      .finally(() => setLoadingWorlds(false))
  }

  const getWorldsRequest = async () => {
    setLoadingWorlds(true)

    const lastUpdateFS = await getLastUpdateRequest('worlds')
    const lastUpdateLS = localStorage.getItem('lastUpdateWorlds')
    const worldsLS = localStorage.getItem('worlds')

    // eslint-disable-next-line eqeqeq
    if (lastUpdateFS == lastUpdateLS && worldsLS?.length >= 1) {
      console.log('Worlds LS')
      setInitialLoad(true)
      setWorlds(JSON.parse(worldsLS))
      setLoadingWorlds(false)
      return
    }

    const worldsRef = collection(db, 'worlds')
    getDocs(worldsRef)
      .then((querySnapshot) => {
        console.log('Worlds FS')
        const worldsData = {}
        querySnapshot.forEach((doc) => {
          worldsData[doc.id] = doc.data()
        })

        localStorage.setItem('worlds', JSON.stringify(worldsData))
        localStorage.setItem('lastUpdateWorlds', lastUpdateFS)

        setInitialLoad(true)
        setWorlds(worldsData)
      })
      .catch((error) => {
        console.error('error :>> ', error)
        showAlert(
          'Error',
          'Error getting worlds data, reload the page and try again',
          'error'
        )
      })
      .finally(() => setLoadingWorlds(false))
  }

  /**
   * Function to update world data
   *
   * @param {string} world - World name
   * @param {Object} values - Object with the values to update
   *
   */
  const updateWorldRequest = (world, level, type, values) => {
    setLoadingWorlds(true)

    const worldRef = doc(db, 'worlds', world)

    updateDoc(worldRef, {
      [level]: { ...worlds[world][level], [type]: values }
    })
      .then(() => {
        setWorlds((worlds) => ({
          ...worlds,
          [world]: {
            ...worlds[world],
            [level]: { ...worlds[world][level], [type]: values }
          }
        }))
        return setLastUpdateRequest('worlds')
      })
      .then(() => {
        showAlert(
          'World updated',
          'World data updated successfully',
          'success'
        )
      })
      .catch((error) => {
        console.error('error :>> ', error)
        showAlert(
          'Error',
          'Error updating world data, reload the page and try again',
          'error'
        )
      })
      .finally(() => setLoadingWorlds(false))
  }

  return (
    <WorldContext.Provider
      value={{
        loadingWorlds,
        initialLoad,
        worlds,

        getWorldRequest,
        getWorldsRequest,
        updateWorldRequest
      }}
    >
      {children}
    </WorldContext.Provider>
  )
}
export { WorldContext, WorldProvider }
