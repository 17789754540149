// #region Dependencies
import { createContext, useState } from 'react'
import { db } from 'services/firebase'
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where
} from 'firebase/firestore'
import { ALL_SCHOOLS } from 'utils/constants'
import { showAlert } from 'utils/alerts'
// #endregion Dependencies

const StudentContext = createContext()
const StudentProvider = ({ children }) => {
  const [loadingStudents, setLoadingStudents] = useState(false)

  const [originalStudents, setOriginalStudents] = useState([])
  const [filteredStudents, setFilteredStudents] = useState([])

  /**
   * Function to get students by the school of the current teacher logged
   */
  const getStudentsBySchoolRequest = (school, superadmin) => {
    setLoadingStudents(true)
    let q
    if (school === ALL_SCHOOLS || !!superadmin) {
      q = query(
        collection(db, 'students'),
        orderBy('school', 'asc'),
        orderBy('grade', 'asc'),
        orderBy('studentId', 'asc')
      )
    }
    if (school !== ALL_SCHOOLS && !superadmin) {
      q = query(
        collection(db, 'students'),
        where('school', '==', school),
        orderBy('grade', 'asc'),
        orderBy('studentId', 'asc')
      )
    }

    getDocs(q)
      .then((querySnapshot) => {
        const studentsData = []
        querySnapshot.forEach((doc) => studentsData.push(doc.data()))
        setOriginalStudents(studentsData)
        setFilteredStudents(studentsData)
      })
      .catch((error) => {
        console.error('error :>> ', error)
        showAlert(
          'Error',
          'Error getting students data, reload the page and try again',
          'error'
        )
      })
      .finally(() => setLoadingStudents(false))
  }

  /**
   * Function to update a student
   *
   * @param {Object} student - Student object with id, school and grade
   */
  const updateStudentRequest = (student) => {
    setLoadingStudents(true)
    const studentRef = doc(db, 'students', student.id)

    setDoc(studentRef, student)
      .then(() => {
        setOriginalStudents((originalStudents) =>
          originalStudents.map((originalStudent) =>
            originalStudent.id === student.id ? student : originalStudent
          )
        )
        setFilteredStudents((filteredStudents) =>
          filteredStudents.map((filteredStudent) =>
            filteredStudent.id === student.id ? student : filteredStudent
          )
        )
        showAlert(
          'Student updated',
          'The student was updated successfully',
          'success'
        )
      })
      .catch((error) => {
        console.error('error :>> ', error)
        showAlert(
          'Error',
          'Error updating student, reload the page and try again',
          'error'
        )
      })
      .finally(() => setLoadingStudents(false))
  }

  const deleteStudentRequest = (student) => {
    setLoadingStudents(true)
    const studentRef = doc(db, 'students', student.id)

    deleteDoc(studentRef)
      .then(() => {
        setOriginalStudents((originalStudents) =>
          originalStudents.filter((s) => s.id !== student.id)
        )
        setFilteredStudents((filteredStudents) =>
          filteredStudents.filter((s) => s.id !== student.id)
        )
        showAlert(
          'Student deleted',
          'The student was deleted successfully',
          'success'
        )
      })
      .catch((error) => {
        console.error('error :>> ', error)
        showAlert(
          'Error',
          'Error deleting student, reload the page and try again',
          'error'
        )
      })
      .finally(() => setLoadingStudents(false))
  }

  /**
   * Function to search students by a term
   *
   * @param {string} searchTerm - Term to search in students
   */
  const searchStudentsByTerm = (searchTerm) => {
    const filtered = originalStudents.filter(
      (student) =>
        !searchTerm ||
        student.studentId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.school.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.grade.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredStudents(filtered)
  }

  return (
    <StudentContext.Provider
      value={{
        loadingStudents,

        originalStudents,
        filteredStudents,

        getStudentsBySchoolRequest,
        updateStudentRequest,
        deleteStudentRequest,
        searchStudentsByTerm
      }}
    >
      {children}
    </StudentContext.Provider>
  )
}
export { StudentContext, StudentProvider }
