// #region Dependencies
import { HashRouter as Router } from 'react-router-dom'
import { AppRouter } from 'router/AppRouter'
import { UserProvider } from 'context/UserContext'
// #endregion Dependencies

const App = () => {
  return (
    <>
      <UserProvider>
        <Router>
          <AppRouter />
        </Router>
      </UserProvider>
    </>
  )
}
export default App
