// #region Dependencies
import { Suspense, lazy, useContext, useEffect, useState } from 'react'
import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { UserContext } from 'context/UserContext'

import { ProtectedRoute } from 'router/ProtectedRoute'
import { PublicRoute } from 'router/PublicRoute'

import { WORLDS } from 'utils/constants'
import { PROJECT } from 'project'
import { Loader } from 'components/Loader'
import { AdminProvider } from 'context/AdminContext'
import { StudentProvider } from 'context/StudentContext'
import { WorldProvider } from 'context/WorldContext'
import { AdminRoute } from './AdminRoute'
// #endregion Dependencies

const ForgotPassword = lazy(() => import('pages/Auth/ForgotPassword'))
const ResetPassword = lazy(() => import('pages/Auth/ResetPassword'))
const Login = lazy(() => import('pages/Auth/Login'))
const Register = lazy(() => import('pages/Auth/Register'))
const Home = lazy(() => import('pages/Home'))
const Tour = lazy(() => import('pages/Tour'))
const Students = lazy(() => import('pages/Dashboard/Students/Students'))
const Admins = lazy(() => import('pages/Dashboard/Admins/Admins'))
const Texts = lazy(() => import('pages/Dashboard/Worlds/Texts/Texts'))
const Questions = lazy(() =>
  import('pages/Dashboard/Worlds/Questions/Questions')
)

export const AppRouter = () => {
  const {
    loadingAdmins,
    setIsAuthenticated,
    isAuthenticated,
    getStudentData,
    studentData,
    teacherData
  } = useContext(UserContext)

  const [searchParams, setSearchParams] = useState(
    new URL(window.location).searchParams
  )

  /**
   * Hook useEffect to get id from URL and with that id get the student data
   */
  useEffect(() => {
    const id = searchParams.get('id')

    if (id) {
      setIsAuthenticated(true)
      getStudentData(id)
    }

    if (searchParams.toString().length > 0) {
      const newURL = window.location.href.split('?')[0]
      history.replaceState({}, null, newURL)
      setSearchParams(new URL(newURL).searchParams)
    }
  }, [])

  if (searchParams.toString().length === 0) {
    return (
      <>
        {loadingAdmins && <Loader />}
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Outlet />}>
              {PROJECT === WORLDS.HOME && (
                <>
                  <Route
                    element={
                      <ProtectedRoute isAuthenticated={!!isAuthenticated} />
                    }
                  >
                    <Route
                      path="worlds"
                      element={<>{studentData && <Home />}</>}
                    />
                    <Route
                      path="students"
                      element={
                        <StudentProvider>
                          <Students />
                        </StudentProvider>
                      }
                    />

                    <Route
                      path="admin"
                      element={
                        <AdminRoute isSuperAdmin={teacherData?.superadmin} />
                      }
                    >
                      <Route
                        path="users"
                        element={
                          <AdminProvider>
                            <Admins />
                          </AdminProvider>
                        }
                      />

                      <Route
                        path="texts"
                        element={
                          <WorldProvider>
                            <Texts />
                          </WorldProvider>
                        }
                      />

                      <Route
                        path="questions"
                        element={
                          <WorldProvider>
                            <Questions />
                          </WorldProvider>
                        }
                      />

                      <Route path="" element={<Navigate to={'/admin/users'} />} />
                      <Route path="*" element={<Navigate to={'/admin/users'} />} />
                    </Route>
                  </Route>

                  <Route
                    element={
                      <PublicRoute isAuthenticated={!!isAuthenticated} />
                    }
                  >
                    <Route path="register" element={<Register />} />
                    <Route path="login" element={<Login />} />
                    <Route
                      path="forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route path="reset-password" element={<ResetPassword />} />
                  </Route>

                  <Route path="" element={<Navigate to={'/login'} />} />
                  <Route path="*" element={<Navigate to={'/login'} />} />
                </>
              )}

              {PROJECT !== WORLDS.HOME && (
                <>
                  <Route
                    element={
                      <ProtectedRoute isAuthenticated={!!isAuthenticated} />
                    }
                  >
                    <Route
                      path=""
                      element={
                        <>
                          {studentData && (
                            <WorldProvider>
                              <Tour />
                            </WorldProvider>
                          )}
                        </>
                      }
                    />
                  </Route>
                  <Route path="*" element={<Navigate to={'/'} />} />
                </>
              )}
            </Route>
          </Routes>
        </Suspense>
      </>
    )
  }
}
