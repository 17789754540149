// #region Dependencies
import { useContext } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { UserContext } from 'context/UserContext'
import { WORLDS } from 'utils/constants'
import { PROJECT } from 'project'
// #endregion Dependencies

export const PublicRoute = ({ isAuthenticated }) => {
  const { isTeacher } = useContext(UserContext)

  if (isAuthenticated) {
    if (PROJECT === WORLDS.HOME) {
      if (isTeacher) return <Navigate to="/students" /> // If the user is authenticated, the project is HOME and the user is a teacher, redirect to /dashboard route in current project
      if (!isTeacher) return <Navigate to="/worlds" /> // If the user is authenticated, the project is HOME and the user is not a teacher, redirect to /worlds route in current project
    }
    if (PROJECT !== WORLDS.HOME) return <Navigate to="/worlds" /> // If the user is authenticated and the project is not HOME, redirect to /worlds route in current project
  }
  return <Outlet />
}
