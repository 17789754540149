export const MAX_COINS = 9
export const TABLE_PAGE_SIZE = 5
export const CHART_COLORS = [
  '#0dc3f9',
  '#d1df4e',
  '#005570',
  '#f45c5e',
  '#6daab9'
]

/**
 * Regex de contraseña segura (mínimo 8 caracteres, 1 mayúscula, 1 minúscula, 1 número y 1 carácter especial)
 * @type {string}
 */
export const REGEX_CONTRASENA =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/

export const STUDENT_OBJ = {
  studentId: '',
  name: '',
  school: '',
  grade: '',
  medical: {
    coin: 0,
    key: 0,
    q1: 0,
    q2: 0,
    q3: 0
  },
  tech: {
    coin: 0,
    key: 0,
    q1: 0,
    q2: 0,
    q3: 0
  },
  service: {
    coin: 0,
    key: 0,
    q1: 0,
    q2: 0,
    q3: 0
  },
  nature: {
    coin: 0,
    key: 0,
    q1: 0,
    q2: 0,
    q3: 0
  },
  build: {
    coin: 0,
    key: 0,
    q1: 0,
    q2: 0,
    q3: 0
  }
}

export const GRADES = ['5th grade', '6th grade', '7th grade', '8th grade']
export const ALL_SCHOOLS = 'all'
export const SCHOOLS = [
  'Big Walnut Middle School',
  'Buckeye Valley Middle School',
  'Delaware- Dempsey Middle School',
  'Olentangy- Berkshire Middle School',
  'Olentangy- Berlin Middle School',
  'Olentangy- Hyatts Middle School',
  'Olentangy- Liberty Middle School',
  'Olentangy- Orange Middle School',
  'Olentangy- Shanahan Middle School',
  'Ohio School for the Deaf',
  'Worthington- Kilbourne Middle School',
  'Worthington- McCord Middle School',
  'Worthington- Phoenix Middle School',
  'Worthington- Worthingway Middle School',
  'Westerville- Blendon Middle School',
  'Westerville- Genoa Middle School',
  'Westerville- Heritage Middle School',
  'Westerville- Minerva Park Middle School',
  'Westerville- Walnut Springs Middle School',
  'Other (Online or home-schooling student)'
]

export const WORLDS = {
  HOME: 'home',
  MEDICAL: 'medical',
  TECH: 'tech',
  SERVICE: 'service',
  NATURE: 'nature',
  BUILD: 'build'
}

export const TEXT_TYPES = {
  characterStory: 'characterStory',
  object1: 'object1',
  object2: 'object2'
}

export const TEXT_LABELS = {
  [TEXT_TYPES.characterStory]: 'Character story',
  [TEXT_TYPES.object1]: '3D object #1',
  [TEXT_TYPES.object2]: '3D object #2'
}
