import { WORLDS } from 'utils/constants'
import { LINKS, PROJECT } from 'project'

/**
 * Function to parse the URL of the worlds
 *
 * @param {string} link - Link to parse
 * @returns Link parsed
 */
export const parseUrl = (link) => {
  let urlSplitted = `${window.location.origin}${window.location.pathname}`
  if (PROJECT !== WORLDS.HOME) urlSplitted = urlSplitted.split(LINKS[PROJECT.toUpperCase()])[0]

  return `${urlSplitted}${link}`
}
