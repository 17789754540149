// #region Dependencies
import { createContext, useState } from 'react'
import { auth, db } from 'services/firebase'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore'
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updateCurrentUser
} from 'firebase/auth'
import { showAlert } from 'utils/alerts'
import {
  getLastUpdateRequest,
  setLastUpdateRequest
} from 'utils/handleLastUpdate'
// #endregion Dependencies

const AdminContext = createContext()
const AdminProvider = ({ children }) => {
  const [loadingAdmins, setLoadingAdmins] = useState(false)
  const [originalAdmins, setOriginalAdmins] = useState([])

  /**
   * Function to get all admins data
   */
  const getAdminsRequest = async () => {
    setLoadingAdmins(true)

    const lastUpdateFS = await getLastUpdateRequest('admins')
    const lastUpdateLS = localStorage.getItem('lastUpdateAdmins')
    const adminsLS = localStorage.getItem('admins')

    // eslint-disable-next-line eqeqeq
    if (lastUpdateFS == lastUpdateLS && adminsLS?.length >= 1) {
      console.log('Admins LS')
      setOriginalAdmins(JSON.parse(adminsLS))
      setLoadingAdmins(false)
      return
    }

    const q = query(
      collection(db, 'admins'),
      where('enabled', '==', true),
      orderBy('updated_at', 'asc')
    )
    getDocs(q)
      .then((querySnapshot) => {
        console.log('Admins FS')
        const adminsData = []
        querySnapshot.forEach((doc) => adminsData.push(doc.data()))

        localStorage.setItem('admins', JSON.stringify(adminsData))
        localStorage.setItem('lastUpdateAdmins', lastUpdateFS)

        setOriginalAdmins(adminsData)
      })
      .catch((error) => {
        console.error('error :>> ', error)
        showAlert(
          'Error',
          'Error getting Admins data, reload the page and try again',
          'error'
        )
      })
      .finally(() => setLoadingAdmins(false))
  }

  /**
   * Function to create admin for school
   *
   * @param {Object} admin - Admin object to create
   */
  const createAdminRequest = async (admin, reset) => {
    setLoadingAdmins(true)
    const currentDate = Date.now()

    const adminRef = doc(db, 'admins', admin.email)
    const adminExists = await getDoc(adminRef).then((doc) => doc.exists())

    // If admin exists, enable it
    if (adminExists) {
      return Promise.all([
        updateDoc(adminRef, {
          enabled: true,
          name: admin.name,
          school: admin.school,
          updated_at: currentDate
        }),
        sendPasswordResetEmail(auth, admin.email)
      ])
        .then(() => {
          setOriginalAdmins((originalAdmins) => [admin, ...originalAdmins])
          return setLastUpdateRequest('admins')
        })
        .then(() => {
          reset()
          showAlert(
            'Admin enabled',
            'The Admin was enabled successfully',
            'success'
          )
        })
        .catch((err) => {
          console.error('error :>> ', err)
          showAlert(
            'Error',
            'Error enabling Admin, reload the page and try again',
            'error'
          )
        })
        .finally(() => setLoadingAdmins(false))
    }

    // If admin doesn't exists, create it
    if (!adminExists) {
      const currentAuthenticatedUser = auth.currentUser
      return createUserWithEmailAndPassword(auth, admin.email, admin.email)
        .then(({ user }) => {
          admin.uid = user.uid
          admin.enabled = true
          admin.superadmin = false
          admin.created_at = currentDate
          admin.updated_at = currentDate
          return updateCurrentUser(auth, currentAuthenticatedUser)
        })
        .then(() =>
          Promise.all([
            setDoc(adminRef, admin),
            sendPasswordResetEmail(auth, admin.email)
          ])
        )
        .then(() => {
          setOriginalAdmins((originalAdmins) => [admin, ...originalAdmins])
          return setLastUpdateRequest('admins')
        })
        .then(() => {
          reset()
          showAlert(
            'Admin created',
            'The Admin was created successfully',
            'success'
          )
        })
        .catch((err) => {
          console.error('error :>> ', err)
          showAlert(
            'Error',
            'Error creating Admin, reload the page and try again',
            'error'
          )
        })
        .finally(() => setLoadingAdmins(false))
    }
  }

  /**
   * Function to update admin data
   *
   * @param {Object} admin - Admin object to update
   */
  const updateAdminRequest = (admin) => {
    setLoadingAdmins(true)
    const adminRef = doc(db, 'admins', admin.email)

    setDoc(adminRef, admin)
      .then(() => {
        setOriginalAdmins((originalAdmins) =>
          originalAdmins.map((originalAdmin) =>
            originalAdmin.email === admin.email ? admin : originalAdmin
          )
        )
        return setLastUpdateRequest('admins')
      })
      .then(() => {
        showAlert(
          'Admin updated',
          'The Admin was updated successfully',
          'success'
        )
      })
      .catch((error) => {
        console.error('error :>> ', error)
        showAlert(
          'Error',
          'Error updating Admin, reload the page and try again',
          'error'
        )
      })
      .finally(() => setLoadingAdmins(false))
  }

  /**
   * Function to delete admin data
   *
   * @param {Object} admin - Admin object to delete
   */
  const deleteAdminRequest = (admin) => {
    setLoadingAdmins(true)
    const adminRef = doc(db, 'admins', admin.email)

    updateDoc(adminRef, { enabled: false })
      .then(() => {
        setOriginalAdmins((originalAdmins) =>
          originalAdmins.filter(
            (originalAdmin) => originalAdmin.email !== admin.email
          )
        )
        return setLastUpdateRequest('admins')
      })
      .then(() => {
        showAlert(
          'Admin disabled',
          'The Admin was disabled successfully',
          'success'
        )
      })
      .catch((error) => {
        console.error('error :>> ', error)
        showAlert(
          'Error',
          'Error deleting Admin, reload the page and try again',
          'error'
        )
      })
      .finally(() => setLoadingAdmins(false))
  }

  return (
    <AdminContext.Provider
      value={{
        loadingAdmins,

        originalAdmins,

        getAdminsRequest,
        createAdminRequest,
        updateAdminRequest,
        deleteAdminRequest
      }}
    >
      {children}
    </AdminContext.Provider>
  )
}
export { AdminContext, AdminProvider }
