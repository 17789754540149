// #region Dependencies
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from 'services/firebase'
// #endregion Dependencies

/**
 * Function to get the last update date of the data in Firestore.
 * @returns {Promise<number>} Date of the last update in milliseconds.
 */
export const getLastUpdateRequest = async (ref) => {
  const globalRef = doc(db, 'global', ref)
  const globalDoc = await getDoc(globalRef)
  const globalData = globalDoc.data()
  return globalData.lastUpdate
}

/**
 * Function to set the last update date of the data in Firestore.
 * @returns {Promise<number>} Current date in milliseconds.
 */
export const setLastUpdateRequest = async (ref) => {
  const globalRef = doc(db, 'global', ref)
  const now = Date.now()
  await setDoc(globalRef, { lastUpdate: now })
  return now
}
