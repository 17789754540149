// #region Dependencies
import { Outlet, Navigate } from 'react-router-dom'
import { WORLDS } from 'utils/constants'
import { parseUrl } from 'utils/parseUrl'
import { PROJECT, LINKS } from 'project'
import { NavBar } from 'components/NavBar'
// #endregion Dependencies

export const ProtectedRoute = ({ isAuthenticated }) => {
  if (!isAuthenticated) {
    if (PROJECT === WORLDS.HOME) return <Navigate to="/login" /> // If user is not authenticated and the project is HOME, redirect to login route in current project
    if (PROJECT !== WORLDS.HOME) {
      return window.location.replace(parseUrl(LINKS.HOME)) // If user is not authenticated and the project is not HOME, redirect to HOME project
    }
  }

  return (
    <>
      {(PROJECT === WORLDS.HOME && !window.location.href.includes('worlds')) && <NavBar />}
      <Outlet />
    </>
  )
}
