// #region Dependencies
import { WORLDS } from 'utils/constants'
// #endregion Dependencies

/**
 * Can be:
 * WORLDS.HOME - WORLDS.MEDICAL - WORLDS.TECH - WORLDS.SERVICE - WORLDS.NATURE - WORLDS.BUILD
 */
export const PROJECT = WORLDS.HOME

/**
 * Set the URL for each project.
 * If you fill 'CYCP-Medical', the final URL will be: https://daccexplorer.com/CYCP-Medical
 */
export const LINKS = {
  HOME: '',
  MEDICAL: 'CYCP-Medical',
  TECH: 'CYCP-Tech',
  SERVICE: 'CYCP-Service',
  NATURE: 'CYCP-Nature',
  BUILD: 'CYCP-Build'
}
