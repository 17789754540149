// #region Dependencies
import { createContext, useState } from 'react'
import { auth, db } from 'services/firebase'
import {
  confirmPasswordReset,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  where
} from 'firebase/firestore'
import { STUDENT_OBJ } from 'utils/constants'
import { showAlert } from 'utils/alerts'
import { PROJECT } from 'project'
// #endregion Dependencies

const UserContext = createContext()
const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState({
    auth: false,
    students: false
  })

  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const [isTeacher, setIsTeacher] = useState(false)
  const [teacherData, setTeacherData] = useState()

  const [studentData, setStudentData] = useState()
  /**
   * Function to login a teacher in dashboard
   * @param {Object} teacher - Teacher object with email and password
   * @param {Function} setFormErrors - Function to set form errors in Login component
   */
  const loginTeacher = (teacher, setFormErrors) => {
    setLoading((loading) => ({ ...loading, auth: true }))
    signInWithEmailAndPassword(auth, teacher.email, teacher.password)
      .then(({ user }) => getDoc(doc(db, 'admins', user.email)))
      .then((doc) => {
        const docData = doc.data()
        if (!docData.enabled) {
          signOut(auth)
          showAlert(
            'Account disabled',
            'Your account has been disabled',
            'error'
          )
          return
        }
        setTeacherData({ ...docData, email: teacher.email })
        setIsTeacher(true)
        setIsAuthenticated(true)
      })
      .catch((error) => {
        console.error('error :>> ', error.code)
        showAlert(
          'Error logging',
          'Verify your email or password and try again',
          'error'
        )
        if (error.code === 'auth/user-not-found') {
          setFormErrors((formErrors) => ({
            ...formErrors,
            email: ['User not found']
          }))
          return
        }
        if (
          error.code === 'auth/invalid-login-credentials' ||
          error.code === 'auth/invalid-email' ||
          error.code === 'auth/wrong-password' ||
          error.code === 'auth/internal-error'
        ) {
          setFormErrors((formErrors) => ({
            ...formErrors,
            password: ['Wrong email or password']
          }))
          return
        }
        setFormErrors((formErrors) => ({
          ...formErrors,
          password: ['Error logging teacher, reload the page and try again']
        }))
      })
      .finally(() => setLoading((loading) => ({ ...loading, auth: false })))
  }

  /**
   * Function to send a password reset email to admin in dashboard
   *
   * @param {Object} data - Object with email
   * @param {Function} setFormErrors - Callback to set form errors
   * @param {Function} reset - Callback to reset form values
   * @returns
   */
  const forgotPasswordTeacher = (data, setFormErrors, reset) => {
    setLoading((loading) => ({ ...loading, auth: true }))

    sendPasswordResetEmail(auth, data.email)
      .then(() => {
        showAlert(
          'Password reset',
          'A password reset link has been sent to your email address',
          'success'
        )
        reset()
      })
      .catch((error) => {
        console.error('error :>> ', error)
        showAlert(
          'Error resetting password',
          'Verify your email and try again',
          'error'
        )
        if (error.code === 'auth/user-not-found') {
          setFormErrors((formErrors) => ({
            ...formErrors,
            email: ['User not found']
          }))
          return
        }
        if (
          error.code === 'auth/invalid-email' ||
          error.code === 'auth/internal-error'
        ) {
          setFormErrors((formErrors) => ({
            ...formErrors,
            password: ['Wrong email']
          }))
          return
        }
        setFormErrors((formErrors) => ({
          ...formErrors,
          studentId: [
            'Error sending reset email, reload the page and try again'
          ]
        }))
      })
      .finally(() => setLoading((loading) => ({ ...loading, auth: false })))
  }

  /**
   * Function to reset password of admin in dashboard
   *
   * @param {Object} data - Object with oobCode and password
   * @param {Function} setFormErrors - Callback to set form errors
   * @param {Function} navigate - Callback to navigate to another view
   * @returns
   */
  const resetPasswordTeacher = (data, setFormErrors, navigate) => {
    setLoading((loading) => ({ ...loading, auth: true }))
    confirmPasswordReset(auth, data.oobCode, data.password)
      .then(() => {
        showAlert(
          'Password updated',
          'Your password has been updated successfully',
          'success'
        )
        navigate('/login')
      })
      .catch((error) => {
        console.error('error :>> ', error)
        showAlert(
          'Error resetting password',
          'Reset link is invalid or has expired',
          'error'
        )
        if (
          error.code === 'auth/invalid-action-code' ||
          error.code === 'auth/internal-error'
        ) {
          setFormErrors((formErrors) => ({
            ...formErrors,
            passwordConfirmation: ['Reset link is invalid or has expired']
          }))
          return
        }
        setFormErrors((formErrors) => ({
          ...formErrors,
          passwordConfirmation: [
            'Error resetting password, reload the page and try again'
          ]
        }))
      })
      .finally(() => setLoading((loading) => ({ ...loading, auth: false })))
  }

  /**
   * Function to logout a teacher in dashboard
   */
  const logoutTeacher = () => {
    setLoading((loading) => ({ ...loading, auth: true }))
    signOut(auth)
      .then(() => {
        setIsAuthenticated(false)
        setIsTeacher(false)
        setTeacherData(0)
      })
      .catch((error) => {
        console.error('error :>> ', error)
        showAlert(
          'Error',
          'Error logging out teacher, reload the page and try again',
          'error'
        )
      })
      .finally(() => setLoading((loading) => ({ ...loading, auth: false })))
  }

  /**
   * Function to register a student in database
   *
   * @param {Object} student - Student object with id, name, school and grade
   * @param {Function} setFormErrors - Function to set form errors in Register component
   */
  const registerStudent = (student, setFormErrors) => {
    setLoading((loading) => ({ ...loading, students: true }))

    const q = query(
      collection(db, 'students'),
      where('studentId', '==', student.studentId),
      where('school', '==', student.school),
      limit(1)
    )

    let newStudent
    getDocs(q)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          setFormErrors((formErrors) => ({
            ...formErrors,
            studentId: ['Student already exists']
          }))
          return
        }

        const studentRef = doc(collection(db, 'students'))
        newStudent = {
          ...STUDENT_OBJ,
          ...student,
          id: studentRef.id
        }

        return setDoc(studentRef, newStudent)
      })
      .then(() => {
        if (newStudent?.id) {
          setStudentData(newStudent)
          setIsAuthenticated(true)
        }
      })
      .catch((error) => {
        console.error('error :>> ', error)
        showAlert(
          'Error',
          'Error registering student, reload the page and try again',
          'error'
        )
        setFormErrors((formErrors) => ({
          ...formErrors,
          studentId: [
            'Error registering student, reload the page and try again'
          ]
        }))
      })
      .finally(() =>
        setLoading((loading) => ({ ...loading, students: false }))
      )
  }

  /**
   * Function to login a student in tour
   *
   * @param {Object} student - Student object with id, school and grade
   * @param {Function} setFormErrors - Function to set form errors in Login component
   */
  const loginStudent = (student, setFormErrors) => {
    setLoading((loading) => ({ ...loading, students: true }))

    const q = query(
      collection(db, 'students'),
      where('studentId', '==', student.studentId),
      where('school', '==', student.school),
      limit(1)
    )

    getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          setFormErrors((formErrors) => ({
            ...formErrors,
            studentId: ['Student not found']
          }))
          return
        }
        setStudentData(querySnapshot.docs[0].data())
        setIsAuthenticated(true)
      })
      .catch((error) => {
        console.error('error :>> ', error)
        showAlert(
          'Error',
          'Error logging student, reload the page and try again',
          'error'
        )
        setFormErrors((formErrors) => ({
          ...formErrors,
          studentId: ['Error logging student, reload the page and try again']
        }))
      })
      .finally(() =>
        setLoading((loading) => ({ ...loading, students: false }))
      )
  }

  /**
   * Function to logout a student
   */
  const logoutStudent = () => {
    setStudentData()
    setIsAuthenticated(false)
  }

  /**
   * Function to get current student data
   * @param {number} id - Student User id to get data
   */
  const getStudentData = (id) => {
    setLoading((loading) => ({ ...loading, students: true }))
    const studentRef = doc(db, 'students', id)
    getDoc(studentRef)
      .then((doc) => {
        if (!doc.exists()) return setIsAuthenticated(false)

        const studentData = doc.data()
        setStudentData(studentData)
        setIsAuthenticated(true)
      })
      .catch((error) => {
        console.error('error :>> ', error)
        showAlert(
          'Error',
          'Error getting student data, reload the page and try again',
          'error'
        )
      })
      .finally(() =>
        setLoading((loading) => ({ ...loading, students: false }))
      )
  }

  /**
   * Function to update student data
   *
   * @param {Object} tourData - Current world data to update
   */
  const updateStudentAnswers = (tourData) => {
    setLoading((loading) => ({ ...loading, students: true }))
    const studentRef = doc(db, 'students', studentData.id)

    const newStudentData = { ...studentData }
    newStudentData[PROJECT] = tourData

    setDoc(studentRef, newStudentData)
      .then(() => setStudentData(newStudentData))
      .finally(() =>
        setLoading((loading) => ({ ...loading, students: false }))
      )
  }

  return (
    <UserContext.Provider
      value={{
        loading,
        isAuthenticated,
        setIsAuthenticated,
        setIsTeacher,
        isTeacher,

        teacherData,
        studentData,

        forgotPasswordTeacher,
        resetPasswordTeacher,
        loginTeacher,
        logoutTeacher,

        registerStudent,
        loginStudent,
        logoutStudent,
        getStudentData,
        updateStudentAnswers
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
export { UserContext, UserProvider }
